import type { ReactNode } from "react";
import classNames from "classnames";

import { Tabs } from "@repo/nerdwallet-components";

import styles from "./CardTableDetails.module.less";

interface CardTableDetailsProps {
  tagline?: any;
  qualificationsBullets?: any;
  heading?: string;
  bottomLine?: any;
  marketingBullets?: string[];
  termsAndFeesLink?: string;
  termsAndFeesDirectLink?: string;
  skuImpressionId?: string;
  fullWidth?: boolean;
  quickFacts?: {
    drivers: {}[];
    facts: string[];
    columned: boolean;
  };
  prosAndCons?: ReactNode;
  whyWeLikeIt?: ReactNode;
}

const CardTableDetails = ({
  heading,
  bottomLine,
  fullWidth,
  prosAndCons,
  whyWeLikeIt,
}: CardTableDetailsProps) => {
  const initialTabs = [
    prosAndCons && { label: "Pros & Cons", content: prosAndCons },
  ];

  const tabs = [
    ...initialTabs,
    bottomLine && { label: "Why we like it", content: whyWeLikeIt },
  ].filter(Boolean);

  return (
    <div
      className={classNames(styles.details, { [styles.fullWidth]: fullWidth })}
    >
      <div>
        <Tabs aria-label={heading} tabs={tabs} />
      </div>
    </div>
  );
};

export default CardTableDetails;
